$xs: 10px;
$small: 14px;
$regular: 1.2rem;
$large: 1.7rem;
$lite:#bdbdbd;
// FONTS
.xs{
  font-size: $xs !important;
  line-height: $xs;
}
.rounded-left{
  border-radius: 4px 0 0 4px !important;
}
.sm{
  font-size: $small !important;
  line-height: calc($small*1.3);
}
.reg{
  font-size: $regular !important;
  line-height: calc($regular * 1.6);
}
.large{
  font-size: $large !important;
}
.fw-100{font-weight: 100 !important;}
.fw-200{font-weight: 200 !important;}
.fw-300{font-weight: 300 !important;}
.fw-400{font-weight: 400 !important;}
.fw-500{font-weight: 500 !important;}
.fw-600{font-weight: 600 !important;}
.fw-700{font-weight: 700 !important;}
.fw-800{font-weight: 800 !important;}
.italic{
  font-style: italic !important;
}
.xs{
  font-size: 12px;
}
// END FONTS
// COLORS
.white{
  color: #fff !important;
}
.bg-white{
  background-color: #fff !important;
}
.light{ filter: brightness(1.2)}
.lighter{ filter: brightness(1.5)}
.dark{ filter: brightness(.8)}
.darker{ filter: brightness(.5)}
.text-dark{
  color: #232323;
}
.bg-lite{
  background-color: #bdbdbd !important;
}
.bg-dark{
  background-color: #3c3c3c !important;
}
.grey{color: $lite !important}
.bg-glass{
  -webkit-backdrop-filter:blur(10px)!important;
  backdrop-filter:blur(10px)!important;
  background-color:hsla(0,0%,100%,.6)!important;
  // border:2px solid hsla(0,0%,100%,.1)!important;
  // box-shadow:0 0 40px rgba(8,7,16,.6)!important
}
.glass-lite{
  -webkit-backdrop-filter:blur(10px);
  backdrop-filter:blur(10px);
  background-color:hsla(0,0%,100%,.1);
  // border:2px solid hsla(0,0%,100%,.1);
  // box-shadow:0 0 40px rgba(8,7,16,.1)
}



// END COLORS

// LISTS
.list-image{
  height: inherit;
  max-height: 180px;
  width: auto;
  border-radius: 4px;
}
.product-list-image{
  height: auto;
  // max-height: 180px;
  width: 70%;
  border-radius: 4px;
}
// LISTS
// SLIDER
.slider{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  .slide-image-container{
    position: fixed; 
    top: -50%; 
    left: -50%; 
    width: 200%; 
    height: 200%;
    display: flex;
    justify-content: center;
    .slide-image{
      filter: brightness(35%) !important;
      position: absolute; 
      top: 0;   
      bottom: 0; 
      margin: auto; 
      min-width: 100%;
      min-height: 100vh;
    }
  }
}
.slide-content{
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .slide-info{
    height: 70%;
    // min-width: 50%;
    // background-color: rgba(255, 51, 0, 0.429);
    position: absolute; 
    z-index: 1;
    color: #fff;
    .slide-header{
      .slide-title{
        font-size: 4rem;
        line-height: 4.2rem;
      }
      .slide-body{
        font-size: 1.5rem;
      }
    }
  }
}
// SLIDER
// DETAILS
.detail-overlay{
  background-color: #000;
  top: 0;
  left:0;
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 2;
  justify-content: center;
  align-items: center;
  .detail-close{
    position: absolute;
    right:0;
    top:0;
  }
}
.large-container{
  // position: absolute;
  // height: auto;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 10px;
  .detail-image-large{
    margin-top: 20px;
  }
}
.detail-image:hover{
  cursor: pointer !important;
  filter: brightness(.7);
}
.modal-content{
  border-radius: .6rem !important;
  background-color: #ececec !important;
}
.modal-fullscreen{
    width: auto !important;
    margin: 2rem !important;
}
.full-modal{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100vw;
  align-items: center;
  min-height: 300px !important;
  height: 100%;
}
.modal-close{
  width: 150px !important
}
.product-container:hover{
  filter: brightness(.8);
  cursor: pointer;
}
.product-container{
  position: relative;
  // background-color: #3c3c3c !important;
  .product-image-container{
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .product-list-image{
      max-width: 100%;
      border-radius: 4px;
    }
  }; 
  .product-button-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .product-button{
      color: #999;
      font-size: 1.5rem !important;
    }
  }
}
// DETAILS
// 
/* var i int8 = 1 */
/* var str string == "aa" */